.LoadingContainer{
    pointer-events: none;
    transition: 400ms linear all;
}

.LoadingBar{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10002;
    display: none;
    width: 100%;
    height: 2px;
    background: #29d;
    border-radius: 0 1px 1px 0;
    transition: width 350ms;
}

.LoadingPeg{
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 2px;
    border-radius: 50%;
    opacity: .45;
    box-shadow: #29d 1px 0 6px 1px;
}

.SpinningContainer{
    position: fixed;
    top: 35%;
    left: 48%;
    z-index: 10002;
    pointer-events: none;
    transition: 350ms linear all;
}

.SpinningContainer{
    /* width: 25px;
    height: 25px; */
    /* border: solid #29d; */
    border-width: 0 2px 2px 0;
    border-radius: 50%;
    animation: Loading__loading-bar-spinner___1hKY9 1000ms linear infinite;
}


.SpinningIcon {
    width: 75px;
    height: 75px;
    border: solid #29d;
    border-width: 0 2px 2px 0;
    border-radius: 50%;
    animation: Loading__loading-bar-spinner___1hKY9 1000ms linear infinite;
}