/***************
   Utilities
*****************/
.font-dark-red {
  color: #d82424 !important;
}

/***************
   Header
*****************/
.brand-img {
  width: 75%;
  height: auto;
}

.header-bar{
  background: #efefef;
}

.nav-item a {
  color: #5a71b4;
  text-decoration: none;
}

.nav-item a:hover {
  color: #81c030;
  text-decoration: none !important;
}

